var site = site || {};
var prodcat = prodcat || {};
prodcat.data = prodcat.data || {};
var POWERREVIEWS = POWERREVIEWS || '';

(function($) {
  var pr_products = [];
  var drupalPRSettings = Drupal.settings.analytics ? Drupal.settings.analytics.power_reviews_enh_enabled : false;
  // Set up PowerReviews data on product.init
  $(document).on('product.init', '.js-product', function() {
    var wrapperUrl = '';
    var signedIn = '';
    var reviewWrapperUrl = '';
    var pageId = '';
    var signedReviewUrl = '';
    var prDisclosureCode = Drupal.settings.power_reviews && Drupal.settings.power_reviews.disclosure_code ? Drupal.settings.power_reviews.disclosure_code : '';

    if (typeof Drupal.settings.power_reviews !== 'undefined' && typeof Drupal.settings.power_reviews.signed_review_url !== 'undefined') {
      var signedReviewUrl = Drupal.settings.power_reviews.signed_review_url;
    }

    if (POWERREVIEWS && typeof Drupal.settings.power_reviews !== 'undefined' && $(this).data('product-id') !== '') {
      var $this = $(this);
      var isQuickShop = $this.closest('.js-quickshop-container').length > 0;
      var isSppQuiz = $this.hasClass('js-shade-finder-result-prd-card');
      if (isSppQuiz) {
        return;
      }
      var isSppPage = ($(this).hasClass('product--full') || $(this).hasClass('js-product-full-v2') || $(this).hasClass('js-starter-kit-v2') || $(this).hasClass('js-starter-kit-v1') || $(this).hasClass('js-product-full-v3')) && !isQuickShop || $(this).hasClass('js-product-full-v4');
      var productId = $this.data('product-id');
      var productBaseId = productId.substring(4);
      pageId = Drupal.settings.power_reviews.pageId || productBaseId;
      wrapperUrl = Drupal.settings.power_reviews.review_wrapper_url;
      signedIn = site.userInfoCookie.getValue('signed_in') - 0 === 1;

      if (signedReviewUrl && signedIn) {
        wrapperUrl = Drupal.settings.power_reviews.review_wrapper_url_signin;
      } else if (!signedReviewUrl && signedIn) {
        wrapperUrl = Drupal.settings.power_reviews.review_wrapper_url;
      } else {
        wrapperUrl = Drupal.settings.power_reviews.review_wrapper_url;
      }

      reviewWrapperUrl = (wrapperUrl || '/review/create') + '?pageId=' + pageId;
      if (prDisclosureCode) {
        reviewWrapperUrl += '&pr_disclosure_code=' + prDisclosureCode;
      }
      // Get a psueorandom 2 char string
      // getTime is not unique per js call. So this will break if you have the
      // same product/sku cards on the same page.
      var randomBit = Math.random().toString(36).slice(2, 4);
      var reviewsnippetId = 'pr-reviewsnippet-' + productBaseId + '-' + (new Date()).getTime() + randomBit;
      var skuCardId = $this.find('.product-thumb').data('sku-card-id');
      if (skuCardId !== undefined) {
        reviewsnippetId = 'pr-reviewsnippet-' + productBaseId + '-' + skuCardId + '-' + (new Date()).getTime() + randomBit;
      }
      var $reviewsnippet = $('.review-snippet', this);
      $reviewsnippet.attr('id', reviewsnippetId);
      var prLocale;
      var prMerchantId;
      var prLocaleMerchantId;

      if (productBaseId && $reviewsnippet.length && !$reviewsnippet.hasClass('reviews-processed')) {
        $reviewsnippet.addClass('reviews-processed');
        //Bobbibrown CA FR Config Changes
        if (!_.isUndefined(Drupal.settings.power_reviews.merchant_ids)) {
          prLocaleMerchantId = get_power_review_merchant_id();
          prLocale = document.location.hostname.search('fr.bobbibrowncosmetics.ca') !== -1 ? Drupal.settings.power_reviews.locale_fr : prLocaleMerchantId[0]['locale'];
          prMerchantId = document.location.hostname.search('fr.bobbibrowncosmetics.ca') !== -1 ? Drupal.settings.power_reviews.merchant_id_fr : prLocaleMerchantId[0]['merchant_id'];
        } else {
          prLocale = document.location.hostname.search('fr.bobbibrowncosmetics.ca') !== -1 ? Drupal.settings.power_reviews.locale_fr : Drupal.settings.power_reviews.locale;
          prMerchantId = document.location.hostname.search('fr.bobbibrowncosmetics.ca') !== -1 ? Drupal.settings.power_reviews.merchant_id_fr : Drupal.settings.power_reviews.merchant_id;
        }

        var pr_product = {
          api_key: Drupal.settings.power_reviews.api_key,
          locale: prLocale,
          merchant_group_id: Drupal.settings.power_reviews.merchant_group_id,
          merchant_id: prMerchantId,
          page_id: pageId,
          review_wrapper_url: reviewWrapperUrl,
          on_render: function(config, data) {
            if (isSppPage) {
              $('.pr-snippet-stars-container').once().on('click', function() {
                pr_product.on_read_reviews_click();
              });
            }
            // MTA-76 Analytics enhancements for read/write reviews Starts
            if (drupalPRSettings) {
              // Track sort option in reviews page
              $('#pr-rd-sort-by').once().on('change', function() {
                if (typeof site !== 'undefined' && typeof site.track !== 'undefined') {
                  var obj = {
                    sort_option: $('option:selected', this).text()
                  };
                  site.track.sortReviews(obj);
                }
              });
              // Track write revew button in reviews section
              $('.spp_reviews_button').once().on('click', function() {
                if (typeof site !== 'undefined' && typeof site.track !== 'undefined') {
                  site.track.reviewWriteStart();
                }
              });
            }
            // MTA-76 Analytics enhancements for read/write reviews Ends
          },
          components: {
          }
        };

        if (isSppPage) {
          var product = prodcat.data.getProduct($(this).data('product-id'));
          pr_product.on_read_reviews_click = function() {
            $('.js-pr-rating-tab', $('.responsive-tabs--powerreviews')).click();
            $('html, body').animate({
              'scrollTop': $('#pr-reviewdisplay').offset().top
            }, 1000);
            if (typeof site !== 'undefined' && typeof site.track !== 'undefined') {
              site.track.reviewRead();
            }
          };
          pr_product.on_write_review_click = function(config, data) {
            if (typeof site !== 'undefined' && typeof site.track !== 'undefined') {
              site.track.reviewWriteStart();
            }
            var war_link = $('.pr-snippet-write-review-link').attr('href');
            if (typeof war_link !== 'undefined' && war_link !== '') {
              window.location.href = war_link;
            }
          };
          pr_product.components.ReviewDisplay = 'pr-reviewdisplay';
          pr_product.components.ReviewSnippet = reviewsnippetId;
          var imageUrl = typeof product === 'object' && product.IMAGE_L !== null ? encodeURI(product.IMAGE_L[0]) : encodeURI(product.IMAGE_L);
          var url = encodeURI(product.url);
          var baseUrl = window.location.protocol + '//' + document.location.hostname;
          pr_product.structured_data_product_id = 'pr_seo_snippet';
          pr_product.product = {
            name: strip_html_tags(product.PROD_RGN_NAME),
            url: baseUrl + url,
            image_url: baseUrl + imageUrl,
            description: strip_html_tags(product.DESCRIPTION) ? strip_html_tags(product.DESCRIPTION) : strip_html_tags(product.PROD_RGN_NAME),
            category_name: product.DEFAULT_CAT_ID,
            upc: product.defaultSku.UPC_CODE,
            brand_name: 'Bobbi Brown',
            price: product.defaultSku.PRICE,
            in_stock: (!!product.isShoppable).toString()
          };
        } else {
          pr_product.components.CategorySnippet = reviewsnippetId;
        }
        pr_products.push(pr_product);
      }

      if (Drupal.settings.power_reviews.show_ask_question && isSppPage && !$('#pr-questionsnippet').hasClass('question-processed')) {
        $('#pr-questionsnippet').addClass('question-processed');
        $('.js-pr-ask-tab').removeClass('hidden');
        if (!_.isUndefined(Drupal.settings.power_reviews.merchant_ids)) {
          prLocaleMerchantId = get_power_review_merchant_id();
          prLocale = prLocaleMerchantId[0]['locale'];
          prMerchantId = prLocaleMerchantId[0]['merchant_id'];
        } else {
          prLocale = Drupal.settings.power_reviews.locale;
          prMerchantId = Drupal.settings.power_reviews.merchant_id;
        }
        var pr_product_question = {
          api_key: Drupal.settings.power_reviews.api_key,
          locale: prLocale,
          merchant_group_id: Drupal.settings.power_reviews.merchant_group_id,
          merchant_id: prMerchantId,
          page_id: pageId,
          review_wrapper_url: reviewWrapperUrl,
          on_render: function(config, data) {
            var question_count = data.question_count;
            if (question_count > 0) {
              $('.pr-ask-question-link').addClass('hidden');
              if (!$('.pr-snippet-qa-answer-link').hasClass('count-rendered')) {
                $('.pr-snippet-qa-answer-link').append(' ' + question_count);
                $('.pr-snippet-qa-answer-link').addClass('count-rendered');
              }
            } else {
              $('.pr-snippet-qa-metrics').addClass('hidden');
            }
            // MTA-76 Analytics enhancements for Ask Answer section Starts
            if (drupalPRSettings) {
              // Disable community answer in Ask&Answer section
              if (Drupal.settings.power_reviews.hide_add_answer_button) {
                $('.pr-qa-display-question').addClass('add-your-answer-link--hide');
              }

              // Tracking while start asking a question
              $('#pr-question-form-link').click(function() {
                if (typeof site !== 'undefined' && typeof site.track !== 'undefined' && $(this).text() !== 'Cancel') {
                  site.track.questionAsk();
                }
              });

              // Tracking while start adding an answer for a question
              $('.pr-btn-answer').once('js-answer').each(function() {
                $(this).on('click', function() {
                  if (typeof site !== 'undefined' && typeof site.track !== 'undefined' && $(this).text() !== 'Cancel') {
                    site.track.addAnswerStart();
                  }
                });
              });

              // Tracking sort option in ask and answer section
              $('#pr-qa-sort-by').once('js-sort-answer').each(function() {
                $(this).on('change', function() {
                  if (typeof site !== 'undefined' && typeof site.track !== 'undefined') {
                    var obj = {
                      sort_option: $('option:selected', this).text()
                    };
                    site.track.sortAnswers(obj);
                  }
                });
              });

              // Tracking search action in ask and answer section
              $('.pr-qa-search-btn').once('js-search').each(function() {
                $(this).on('click', function() {
                  if (typeof site !== 'undefined' && typeof site.track !== 'undefined') {
                    var searchTerm = $(this).siblings('#pr-query').attr('value');
                    if (searchTerm && searchTerm !== '') {
                      var obj = {
                        search_term: searchTerm
                      };
                      site.track.questionAskSearch(obj);
                    }
                  }
                });
              });
            }
          },

          // Tracking while submitting a question
          on_submit: function(config, data) {
            if (typeof site !== 'undefined' && typeof site.track !== 'undefined' && drupalPRSettings) {
              site.track.questionAnswer();
            }
          },
          // MTA-76 Analytics enhancements for Ask Answer section Ends
          on_view_answers_click: function() {
            $('.js-pr-ask-tab', $('.responsive-tabs--powerreviews')).click();
            $('html, body').animate({
              'scrollTop': $('#pr-questiondisplay').offset().top
            }, 1000);
            // MTA-76 Tracking while start reading Q & A section
            if (typeof site !== 'undefined' && typeof site.track !== 'undefined' && drupalPRSettings) {
              site.track.questionAsk();
            }
          },
          components: {
            QuestionSnippet: 'pr-questionsnippet',
            QuestionDisplay: 'pr-questiondisplay',
          }
        };
        pr_product_question.on_ask_question_click = function(config, data) {
          // MTA-76 Tracking while start reading Q & A section
          if (typeof site !== 'undefined' && typeof site.track !== 'undefined' && drupalPRSettings) {
            site.track.questionAsk();
          }
        };
        pr_products.push(pr_product_question);
      }
    }
  });

  // Render power reviews after products have been initialized
  $(document).on('products.init-start', function() {
    pr_products = [];
  });

  // Render power reviews after products have been initialized
  $(document).on('products.init-finish', function() {

    if (POWERREVIEWS && typeof Drupal.settings.power_reviews !== 'undefined' && typeof POWERREVIEWS.display !== 'undefined' && pr_products.length !== 0) {
      POWERREVIEWS.display.render(pr_products);
    }
  });
  $(window).load(function() {
    $('.js-mpp-grid-item--sortable, .js-product-grid-item--sortable').each(function() {
      var $prSnippet = $('.pr-snippet-rating-decimal', this);
      if ($prSnippet.length) {
        $(this).attr('data-rating-sort', $prSnippet.text());
      }
    });
  });
  function strip_html_tags(str) {
    if ((str === null) || (str === '')) {
      return false;
    } else {
      str = str.toString();
      return str.replace(/<[^>]*>/g, '');
    }
  }

  // MTA-76 Tracking while submitting an answer for question
  $(document).on('click', '.pr-answer-form .pr-submit .pr-btn-review', function() {
    if (typeof site !== 'undefined' && typeof site.track !== 'undefined' && drupalPRSettings) {
      site.track.addAnswerEnd();
    }
  });

  // MTA-76 Track thumbs up rating for a review
  $(document).on('click', '.pr-rd-helpful-action .pr-helpful-yes', function() {
    if (typeof site !== 'undefined' && typeof site.track !== 'undefined' && drupalPRSettings) {
      var obj = {
        event_action: 'thumbs up'
      };
      site.track.rateReviews(obj);
    }
  });

  // MTA-76 Track thumbs down  rating for a review
  $(document).on('click', '.pr-rd-helpful-action .pr-helpful-no', function() {
    if (typeof site !== 'undefined' && typeof site.track !== 'undefined' && drupalPRSettings) {
      var obj = {
        event_action: 'thumbs down'
      };
      site.track.rateReviews(obj);
    }
  });

  // MTA-76 Track flagging reviews in review page
  $(document).on('click', '.pr-rd-flag-review-container .pr-underline, .pr-flag-review .pr-flag-review-btn-submit', function() {
    if (typeof site !== 'undefined' && typeof site.track !== 'undefined' && drupalPRSettings) {
      var obj = {
        event_action: 'flag this review'
      };
      site.track.rateReviews(obj);
    }
  });

  // Tracking thumbs up rating for an answer
  $(document).on('click', '.pr-qa-display-answer .pr-helpful-yes', function() {
    if (typeof site !== 'undefined' && typeof site.track !== 'undefined' && drupalPRSettings) {
      var obj = {
        event_action: 'thumbs up'
      };
      site.track.rateAnswers(obj);
    }
  });

  // Tracking thumbs down rating for an answer
  $(document).on('click', '.pr-qa-display-answer .pr-helpful-no', function() {
    if (typeof site !== 'undefined' && typeof site.track !== 'undefined' && drupalPRSettings) {
      var obj = {
        event_action: 'thumbs down'
      };
      site.track.rateAnswers(obj);
    }
  });

  // Tracking show more answers click
  $(document).on('click', '.pr-qa-display-show-more-answers .pr-qa-display-text, .pr-qa-display-show-more-questions', function() {
    if (typeof site !== 'undefined' && typeof site.track !== 'undefined' && drupalPRSettings) {
      site.track.showMoreAnswers();
    }
  });

  // Get merchant id and locale for power review based on locale set on the page
  function get_power_review_merchant_id() {
    var prMerchantId = Drupal.settings.power_reviews.merchant_ids;
    var locale = $.cookie('LOCALE') !== null && $.cookie('LOCALE') !== '' ? $.cookie('LOCALE').toLowerCase() : Drupal.settings.power_reviews.default_locale;
    var prLocaleMerchantId = [];
    var prConf = prMerchantId[locale];
    if (prConf) {
      $.each (prConf, function(locale, merchantId) {
        prLocaleMerchantId.push({'locale': locale, 'merchant_id': merchantId});
      });
    }
    return prLocaleMerchantId;
  }
})(jQuery);
